.settings-scroll {
	position: fixed;
	animation: SettingsOptions .8s ease;
	padding-left: 10px;
	top: 70px;
	height: calc(100% - 62px);
}

.settings-scroll .scroll {
	height: calc(100% - 52px);
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	border-right: 2px solid var(--secondary);
}

.settings-scroll h2 {
	margin: 10px;
}

.settings-options {
	top: 47px;
	height: calc(100vh - 47px);
	position: fixed;
	padding-left: 10px;

	overflow-x: hidden;
	overflow-y: scroll;

	animation: 0.8s ease 0s 1 normal none running SettingsOptions;
}

@media only screen and (min-width:900px) {
	.settings-scroll {
		width: 250px
	}

	.settings-options {
		left: 260px;
		width: calc(100vw - 280px)
	}
}

@media only screen and (max-width:900px) {
	.settings-scroll {
		width: 60px
	}

	.settings-scroll .path-link span {
		display: none
	}

	.settings-options {
		left: 70px;
		width: calc(100vw - 90px)
	}
}

@media only screen and (min-width:700px) {
	.settings-scroll .scroll {
		height: calc(100vh - 125px)
	}

	.settings-options {
		height: calc(100vh - 113px);
		top: 110px
	}
}

@media only screen and (max-width:700px) {
	.settings-scroll .scroll {
		height: calc(100vh - 125px)
	}

	.settings-options {
		height: calc(100vh - 113px);
		top: 110px
	}
}

.settings-headbar {
	position: fixed;
	top: 62px;
	background-color: var(--el-back-secondary);
	height: 52px;
	width: 100vw;
	padding: 5px;
	display: flex;
	align-items: center;
}

.settings-sidebar {
	position: fixed;
	right: 0;
	top: 122px;
	width: 220px;
	padding: 10px;
	height: calc(100vh - 122px);
	overflow: scroll;
}

.settings-pagecontainer {
	position: fixed;
	top: 140px;
	left: 45px;
	width: calc(100vw - 300px);
	height: calc(100vh - 160px);
	transition: all .2s;
}

.page {
	padding: 20px 0;
	border-radius: 5px;
	box-shadow: var(--acrylic-high) 0px 5px 15px;
	overflow-x: hidden;
	overflow-y: scroll;
	height: calc(100% - 40px);
}

/* --- Login --- */
.loader {
	--color: #1F5EA0;
	--size-mid: 6vmin;
	--size-dot: 1.5vmin;
	--size-bar: 0.4vmin;
	--size-square: 3vmin;
	position: absolute;
	margin-left: -3vmin;
}

.loader::before,
.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
}

.loader.--4::before {
	height: var(--size-bar);
	width: 6vmin;
	background-color: var(--color);
	animation: loader-4 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

@keyframes loader-4 {

	0%,
	44%,
	88.1%,
	100% {
		transform-origin: left;
	}

	0%,
	100%,
	88% {
		transform: scaleX(0);
	}

	44.1%,
	88% {
		transform-origin: right;
	}

	33%,
	44% {
		transform: scaleX(1);
	}
}

.start {
	width: 100vw;
	height: 60vh;
	padding-top: 40vh;
	background-size: cover;
	background-position: center center;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #297CD3;
	text-align: center;
	letter-spacing: 0.3px;
}

.home {
	position: fixed;
	bottom: 10px;
	padding: 50px 50px 0 50px;
	right: 0;
}

.logo {
	max-height: 50px;
}

.block {
	margin-top: 40px !important;
	margin-bottom: 40px !important;
}

@media only screen and (max-width:900px) {
	.extend {
		display: none
	}
}

header {
	align-items: center;
	background-color: #4f474e;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2), 0 5px 5px 0 rgba(0, 0, 0, .24);
	color: #fff;
	display: flex;
	font-size: 20px;
	font-weight: 300;
	gap: 15px;
	height: 62px;
	justify-content: space-between;
	position: fixed;
	text-align: right;
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	width: 100%
}

.nav-head {
	align-items: center;
	display: flex;
	gap: 15px
}

@media only screen and (min-width:600px) {
	.nav-link {
		align-items: center;
		display: flex;
		gap: 3vh;
		margin-right: 10px
	}

	.nav-ham {
		display: none
	}
}

@media only screen and (max-width:600px) {
	.nav-link {
		display: none
	}

	.nav-ham {
		cursor: pointer;
		display: block
	}
}

@keyframes menuOp {
	0% {
		padding-left: 100px;
		right: -70%
	}

	100% {
		padding-left: 30px;
		right: 0
	}
}

@keyframes menuEnd {
	0% {
		padding-left: 30px;
		right: 0
	}

	100% {
		padding-left: 100px;
		right: -70%
	}
}

.icone {
	float: right;
	height: 46px;
	max-width: 225px;
	padding: 8px;
	position: relative;
	z-index: 11
}

.nav-lien {
	display: block;
	padding-left: 1%;
	padding-right: 2%;
	position: relative;
	text-decoration: none;
	width: 100%
}

table i,
table th,
table u {
	color: var(--blue-primary)
}

.table2 {
	background-color: #fff
}

.column {
	display: inline-block;
	width: 300px
}

.index-account {
	background-color: var(--el-back-primary);
	background-position: center center;
	background-size: cover;
	border: 2px solid var(--secondary);
	cursor: pointer;
	border-radius: 5px;
	margin: 10px;
	max-height: 230px
}

.index-account:hover {
	background-color: var(--el-back-primary);
	background-position: center center;
	background-size: cover;
	border: 2px solid var(--blue-primary);
	box-shadow: var(--el-back-primary) 0 5px 15px
}

.index-account:hover .space {
	border-bottom: 2px solid var(--blue-primary)
}

.index-account div {
	background-color: var(--img-hover);
	border-radius: 3px;
	padding: 10px
}

.index-img {
	max-height: 60px;
	position: relative
}

@media only screen and (max-width:1000px) {
	.index-column {
		width: 100%;
		text-align: left;
	}
}

@media only screen and (min-width:1000px) {
	.index-column {
		width: calc(100% / 2 - 10px);
		text-align: center;
		display: inline-block;
		margin: 10px;
	}
}

.settings-head {
	background-color: var(--el-back-primary);
	background-position: center center;
	background-size: cover;
	border: 2px solid var(--blue-primary);
	border-radius: 5px;
	color: var(--blue-primary);
	margin: 10px
}

.settings-head div {
	background-color: var(--img-hover);
	border-radius: 3px;
	padding: 10px
}

.settings-account img,
.settings-link img {
	position: relative;
	top: 5px
}

.settings-link {
	border: 2px solid #868686;
	display: block;
}

.Img-company {
	max-width: 500px;
	max-height: 150px
}

.settings-options {
	overflow-x: hidden;
	overflow-y: scroll;
	padding-left: 10px;
	position: fixed
}

.himg {
	width: 35px;
	position: relative;
	top: 8px
}

.text {
	background-color: #00000014;
	border-radius: 5px
}

.text:active,
.text:focus,
.text:hover {
	border: 3px solid #297CD3AA;
	background-color: #fff2
}

@keyframes waiting {
	0% {
		background-color: #ddd
	}

	33% {
		background-color: #ddd
	}

	66% {
		background-color: #bbb
	}

	100% {
		background-color: #ddd
	}
}

.Départ {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 370px;
	width: 100vw;
	z-index: 1
}

.progress_size {
	height: 1vmin;
	margin-right: 11px;
	position: relative;
	border-radius: 5px;
	transition: all .5s
}

.round_size {
	border-radius: 5px;
	display: inline-block;
	height: 20px;
	position: relative;
	top: 3px;
	width: 20px
}

.img-help {
	cursor: pointer;
	left: 20px;
	position: relative;
	top: 13px;
	width: 40px;
	display: inline-block;
}

.Header {
	bottom: 0;
	height: 300px;
	left: 0;
	position: absolute;
	top: 0;
	width: 99%;
	z-index: 1
}

.Company {
	bottom: 0;
	left: 0;
	margin-bottom: 40px;
	position: relative;
	right: 0;
	top: 370px;
	width: 100vw;
	z-index: 1
}

#Company {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	align-content: flex-start;
	bottom: 0;
	left: 0;
	max-width: 98vw;
	overflow: hidden;
	position: relative;
	right: 0;
	z-index: 1
}

.Logo {
	max-height: 130px;
	max-width: 270px;
	width: 40vw
}

.Title {
	font-weight: 300;
	left: 8%;
	line-height: 1.4;
	position: absolute
}

.Title-details {
	font-weight: 300;
	left: 8%;
	line-height: 1.4;
	position: absolute
}

.Index-Title {
	font-weight: 300;
	left: 8%;
	line-height: 1.4;
	position: absolute
}

@media only screen and (min-width:600px) {
	.Index-Title {
		font-size: 40px;
		top: 40%
	}

	.Title {
		font-size: 60px;
		top: 160px
	}

	.Title-details {
		font-size: 30px;
		top: 90%
	}
}

@media only screen and (max-width:600px) {
	.Index-Title {
		font-size: 40px;
		top: 30%
	}

	.Title {
		font-size: 40px;
		top: 140px
	}

	.Title-details {
		font-size: 25px;
		top: 65%
	}
}

.New-company {
	font-size: 25px;
	font-weight: 300;
	line-height: 1.4;
	position: absolute;
	right: 8%;
	text-align: right;
	top: 70%
}

.Info-Header {
	font-size: 25px;
	font-weight: 300;
	left: 8%;
	line-height: 1.4;
	position: absolute;
	text-align: right;
	top: 70%
}

.Details {
	font-size: 25px;
	font-weight: 300;
	left: 8%;
	line-height: 1.4;
	position: absolute;
	text-align: right;
	top: 70%
}

.title1 {
	font-size: 30px;
	font-weight: 700
}

.title2 {
	font-size: 27px;
	font-weight: 700
}

.title3 {
	font-size: 24px;
	font-weight: 700
}

.details1 {
	font-size: 15px
}

.details2 {
	font-size: 12px
}

.transparent-circle-picker>.circle-picker>span>div>span>div {
	border: 1px solid;
}

.checked {
	margin-top: 0;
	position: relative;
	top: 7px
}

.checked:after {
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	border: solid #fff;
	border-width: 0 3px 3px 0;
	content: "";
	display: block;
	height: 10px;
	left: 1.5px;
	position: relative;
	top: -2px;
	transform: rotate(45deg);
	width: 5px
}

.rumble {
	animation: 0.4s ease 0s 1 normal none running Rumble;
}

.sel-form {
	border-radius: 5px;
	background-color: var(--acrylic-high);
}

.sel-form input {
	display: none;
}

@keyframes Rumble {
	0% {
		transform: translate(0)
	}

	20% {
		transform: translate(-2px, 2px)
	}

	40% {
		transform: translate(-2px, -2px)
	}

	60% {
		transform: translate(2px, 2px)
	}

	80% {
		transform: translate(2px, -2px)
	}

	100% {
		transform: translate(0)
	}
}

@keyframes alert-input {
	0% {
		border: 2px solid var(--transparent)
	}

	5% {
		border: 2px solid #e29e33
	}

	10% {
		border: 2px solid var(--transparent)
	}

	15% {
		border: 2px solid #e29e33
	}

	20% {
		border: 2px solid var(--transparent)
	}

	25% {
		border: 2px solid #e29e33
	}

	30% {
		border: 2px solid var(--transparent)
	}

	50% {
		border: 2px solid #e29e33
	}

	70% {
		border: 2px solid var(--transparent)
	}

	90% {
		border: 2px solid #e29e33
	}
}

.analyse-img {
	margin-right: 5px;
	position: relative;
	top: 4px;
	width: 20px
}

.over-back {
	display: none;
	height: 100vh;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 99
}

.over-box {
	border-radius: 5px;
	bottom: 0;
	display: block;
	height: calc(100vh - 40px);
	left: 0;
	margin: auto;
	max-height: 450px;
	max-width: 500px;
	padding: 20px;
	position: absolute;
	right: 0;
	top: 0;
	width: calc(100vw - 40px);
	z-index: 100
}

@keyframes Nav {
	0% {
		margin: 38px 4% 0;
		width: 92%
	}

	100% {
		margin: 0;
		width: 100%
	}
}

@keyframes Err {
	0% {
		margin: -70px 0 0
	}

	50% {
		margin: -70px 0 0
	}

	100% {
		margin: 62px 0 0
	}
}

.v-space {
	background-color: #777;
	border-radius: 5px;
	display: inline-block;
	height: 30px;
	margin-top: 0;
	padding-bottom: 0;
	padding-top: 0;
	position: relative;
	top: 3px;
	width: 2px
}

.space {
	display: block;
	margin: 0;
	border-bottom: 2px solid var(--secondary)
}

.hr {
	display: block;
	position: relative;
	padding: 3px;
	margin: 10px 10%;
	border-radius: 999px;
	background-color: var(--text);
}

@keyframes SettingsOptions {
	0% {
		margin-left: 50px;
		margin-right: -50px;
		opacity: 0
	}

	100% {
		margin-left: 0;
		opacity: 1
	}
}

@keyframes SettingsOptionsExit {
	0% {
		margin-left: 0;
		opacity: 1
	}

	100% {
		margin-left: -50px;
		opacity: 0
	}
}

@keyframes SizeBarre {
	0% {
		width: 0
	}

	100% {
		width: 100%
	}
}

.info-img {
	left: 40px;
	position: relative;
	top: -20px;
	width: 25px
}

.DTselIn:active,
.DTselIn:focus,
.DTselIn:hover,
.InfoClass:active,
.InfoClass:focus,
.InfoClass:hover,
.info-trafic-title input:active,
.info-trafic-title input:focus,
.info-trafic-title input:hover {
	background-color: #0000001a;
	border: solid 2px #ffffff8f;
	border-radius: 6px
}

.info-trafic-img:hover .info-trafic-chtype {
	display: block;
	opacity: 1
}

.ribbon {
	margin: 10px 0;
	background-color: var(--el-back-hover);
	border-radius: 5px
}

.ribbon .info {
	position: relative;
	top: -9px;
	margin-left: 15px
}

.progress-bck {
	background-color: var(--acrylic-light);
	border-radius: 5px;
	float: left;
	height: 1vmin;
	position: relative;
	width: 100%
}

.progress {
	background-color: var(--blue-primary);
	border-radius: 5px;
	float: left;
	height: 1vmin;
	position: relative;
	top: -1vmin;
	transition: all .5s;
	width: 0
}

.indeterminate {
	animation: indeterminate 1.3s ease infinite
}

@keyframes indeterminate {
	0% {
		left: 0;
		width: 0
	}

	50% {
		left: 0;
		width: 100%
	}

	100% {
		left: 100%;
		width: 0
	}
}

.hr_or {
	width: 100%;
	display: block;
	height: 4px;
	background: linear-gradient(90deg, var(--transparent) 0%, var(--blue-primary) 15%, var(--blue-primary) 25%, var(--transparent) 40%, var(--transparent) 60%, var(--blue-primary) 75%, var(--blue-primary) 85%, var(--transparent) 100%);
}

.or {
	position: relative;
	top: -14px;
	padding: 5px 15px;
}

.overmouse,
.overmouse2 {
	border-radius: 10px;
	cursor: pointer;
	padding: 10px;
	position: initial;
	transition: background-color 250ms;
}

.overmouse {
	background-color: var(--transparent);
}

.overmouse:hover {
	background-color: var(--el-back-secondary);
}

.overmouse2 {
	background-color: var(--select);
}

.overmouse2:hover {
	background-color: var(--select-hover);
}

.nav-retour {
	top: 54px;
	position: fixed;
	background-color: #6c676c;
	left: -8px;
	padding: 10px;
	border-radius: 10px;
	z-index: 8;
}

.retour {
	color: #fff
}

.nav-lien {
	color: rgba(255, 255, 255, 1)
}

#menu {
	background-color: var(--el-back-secondary);
	box-shadow: 0 0 10px #2f2f2f
}

@media only screen and (max-width:901px) {
	.Settings .menu {
		box-shadow: rgba(50, 50, 93, .25) 30px 0 20px -15px
	}
}

@media only screen and (min-width:901px) {
	.Settings .menu {
		box-shadow: rgba(50, 50, 93, .25) 60px 0 45px -45px
	}
}

.Details-settings {
	color: #d9d9d9;
	color: #d9d9d9
}

.Company {
	box-shadow: 0 0 60px 40px var(--index-shadow)
}

#Company {
	background-color: var(--index-bck)
}

.Title {
	color: #fff;
	text-shadow: 2px 2px 4px #000
}

.Title-details {
	color: #fff;
	text-shadow: 2px 2px 4px #000
}

.Index-Title {
	color: #fff;
	text-shadow: 2px 2px 4px #000
}

.New-company {
	color: #fff;
	text-shadow: 2px 2px 4px #000
}

.Info-Header {
	color: #fff;
	text-shadow: 2px 2px 4px #000
}

.Details {
	color: #fff;
	text-shadow: 2px 2px 4px #000
}

.Search0 {
	background-color: var(--index-bck)
}

.Search1 {
	background-color: var(--body)
}

.over-back {
	background-color: #00000069
}

.over-box {
	box-shadow: rgba(50, 50, 93, .25) 0 50px 100px -20px, rgba(0, 0, 0, .3) 0 30px 60px -30px;
	color: var(--text)
}

input[type=password],
input[type=text] {
	background: var(--el-back-primary);
	color: var(--text)
}

.itinéraires table td {
	padding-left: 10px
}

.time {
	font-size: 23px;
	font-weight: bold;
}

.dest {
	font-size: 20px;
	font-weight: 700
}

.class {
	width: 60px;
	height: 60px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.deleted .dest {
	color: var(--red-secondary);
	text-decoration: line-through
}

.old_time {
	font-size: 20px;
	font-weight: bold;
}

.deleted .time {
	color: var(--red-secondary);
	text-decoration: line-through;
}

.delayed .old_time {
	color: var(--orange-secondary);
	text-decoration: line-through;
}

.before .old_time {
	color: var(--blue-secondary);
	text-decoration: line-through;
}

.details-img {
	position: relative;
	max-height: 25px;
	padding-right: 5px;
	vertical-align: middle;
	transition: all .3s
}

.train_id {
	position: relative;
	top: -12px;
	padding-left: 30px;
	font-size: 20px;
	font-weight: 700
}

.train_details {
	position: relative;
	display: none;
	box-shadow: 1px 20px 10px -9px rgba(0, 0, 0, .4);
	z-index: 3;
	top: -15px
}

.details {
	position: relative;
	display: block;
	padding: 0;
	width: 450px;
	z-index: 10;
	top: -10px;
	margin-left: -30px
}

.info-title {
	height: 50px;
	align-items: center;
	border-radius: 5px;
}

.info-title-show {
	align-items: center;
	border-radius: 8px;

	padding: 0.4rem !important;
}

.info-title-show img {
	max-width: 30px;
}

.info-change-type {
	position: absolute;
	border-radius: 5px;
	box-shadow: 0 0 20px 0 #818181, 0 5px 5px 0 rgba(0, 0, 0, 0.29);
}



/* --- Path link --- */
.path-link {
	display: flex;
	height: 42px;
	width: 100%;
	border-radius: 5px;
	outline: 0;
	cursor: pointer;

	justify-content: left;
	align-items: center;
	border: 2px solid #4f474e00;
	box-sizing: border-box;
	gap: 10px;
	transition: all .3s ease;
}

.path-link img {
	margin-left: 5px;
	max-width: 30px;
}

.path-link:hover:not(.selected),
.path-link:active:not(.selected),
.path-link:focus:not(.selected) {
	background-color: var(--button-back-secondary);
	border: 2px solid #4f474e61;
}

.path-link:not(.selected) span {
	opacity: 0.5;
	transition: opacity .5s;
	font-weight: 500;
}

.path-link:hover:not(.selected) span {
	opacity: 1;
	font-weight: 700;
}

.selected {
	background-color: var(--el-back-primary);
	border: 2px solid var(--blue-primary);
}

.selected span {
	color: var(--blue-primary);
	font-weight: 700;
}

.selected:hover {
	color: var(--blue-primary);
	background-color: #2674C620;
	font-weight: 700;
}

.log-box {
	display: flex;
	justify-content: space-between;
}

.drag {
	width: 7px;
	height: 40px;
	cursor: grab;
	background-color: var(--blue-primary);
	border-radius: 10px;
	z-index: 3;
}

.drag-grip {
	width: 20px;
	cursor: grab;
	position: relative;
	top: 3px;
}


.Toastify__close-button,
.Toastify__close-button:hover {
	max-width: 20px;
	max-height: 20px;
	border: 2px solid #ffffff00 !important;
}


.info-block {
	display: inline-flex;
	align-items: center;
	border-radius: 10px;
	width: 60px;
	height: 60px;
	margin: 10px 5px;
	padding: 10px;
}

.info-block.info-grey {
	border: 2px solid #a9a9a9;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.info-block img {
	max-width: 100%;
}


@media only screen and (min-width: 900px) {
	.info-delete {
		left: 309px;
		width: calc(100vw - 332px) !important;
	}
}

@media only screen and (max-width: 900px) {
	.info-delete {
		left: 119px;
		width: calc(100vw - 142px) !important;
	}
}

.info-delete {
	display: flex;
	position: fixed !important;
	background-color: var(--red-primary);
	height: 46px;
	bottom: 10px;
	border-radius: 5px;
	z-index: 5;
	animation: info-delete .5s ease;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.page-delete {
	display: flex;
	position: fixed !important;
	background-color: var(--red-primary);
	height: 46px;
	bottom: 14px;
	border-radius: 5px;
	z-index: 5;
	animation: info-delete .5s ease;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.page-delete {
	left: 45px;
	width: calc(100vw - 300px) !important;
}

.espace {
	height: 50px;
	border-radius: 5px;
	transition: all .2s;
}

.page .espace:hover {
	background-color: var(--select);
}

@keyframes info-delete {
	0% {
		bottom: -50px;
	}

	100% {
		bottom: 10px;
	}
}

.SNCFd-card {
	background-image: url("../../assets/img/SNCFd.png");
}

.SNCFa-card {
	background-image: url("../../assets/img/SNCFa.png");
}

.IENAd-card {
	background-image: url("../../assets/img/IENAd.png");
}

.IENAa-card {
	background-image: url("../../assets/img/IENAa.png");
}

.RENFEd-card {
	background-image: url("../../assets/img/RENFEd.png");
}

.RENFEa-card {
	background-image: url("../../assets/img/RENFEa.png");
}

.TALOSd-card {
	background-image: url("../../assets/img/TALOSd.png");
}

.TALOSa-card {
	background-image: url("../../assets/img/TALOSa.png");
}

input:active+.selectcard-cc {
	opacity: 0.9;
}

input:checked+.selectcard-cc {
	filter: none;
}

.selectcard-cc {
	cursor: pointer;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	width: 200px;
	height: 111px;
	margin-left: 20px;
	border-radius: 5px;
	transition: all 250ms ease;
	filter: brightness(1.2) grayscale(1) opacity(1);
}

.cc-selector input {
	display: none;
	margin: 0;
	padding: 0;
}

.selectcard-cc:hover {
	filter: brightness(1.2) grayscale(.5) opacity(1);
}

.embed {
	width: 100%;
	aspect-ratio: 16 / 9;
}

.container.control {
	position: absolute !important;
	left: -43px;
	display: flex;
	flex-direction: column;
}

.index-img {
	max-height: 60px;
	position: relative;
}

.index-title {
	text-shadow: 2px 2px 4px var(--body);
	color: var(--acrylic-text);
}

.index-column {
	padding: 15px;
	border-radius: 5px;
	border: 2px solid var(--transparent);
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2);
}

.index-column:hover {
	border: 2px solid var(--blue-primary);
}

@media only screen and (max-width: 1000px) {
	.index-column {
		width: 100%;
		text-align: left;
	}
}

@media only screen and (min-width: 1000px) {
	.index-column {
		width: calc(100% / 2 - 10px);
		text-align: center;
		display: inline-block;
	}
}

.Wallpaper {
	height: 100vh;
	left: 0px;
	position: fixed;
	right: 0px;
	top: 0px;
	z-index: -1;
}

.sel-form button {
	transition: all .5s ease;
}

.sel-form button:not(.sel-selected) {
	filter: grayscale(1);
}

.sel-form button.sel-selected {
	font-weight: bold;
}

.transition {
	transition: all .3s ease;
}

.separateur {
	position: relative;
	background-color: var(--blue-primary);
	display: block;
	padding-top: 4px;
}

/* --- */
.depart {
	padding: 5px 10px 10px 10px;
}


.depart.focused {
	background-color: var(--index-bck);
	border-radius: 10px;
	padding-bottom: 15px;
}

.desserte {
	margin-bottom: 20px;
}

.desserte a {
	cursor: pointer;
}

.stop {
	height: 31px;
	display: block;
	max-width: max-content;
}

.stop-dots {
	background: var(--blue-primary);
	border-radius: 50%;
	display: inline-block;
	height: 10px;
	left: -4px;
	margin-bottom: .5vh;
	margin-right: 10px;
	position: relative;
	top: 3px;
	width: 10px;
}

.stop-line {
	background-color: #137db6;
	display: inline-block;
	height: 27px;
	left: 3px;
	position: relative;
	right: -3px;
	top: -7px;
	width: 4px;
}

.page_img {
	width: 100%;
}
.row1 .page_img, .row3 .page_img, .row5 .page_img {
	border-radius: 6px;
}