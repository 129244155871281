blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
.btnActive {
    opacity: 1;
}

.editor table {
    width: 100%;
    border-collapse: collapse;
}

.editor-iframe, .editor-iframe iframe{
    min-width: 100%;
    min-height: 300px;
}

td {
    height: 50px;
    padding: 0 5px;
}


.color-options {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    gap: 5px;
}

.clicked {
    border: 1px solid lightgray;
    border-bottom: none;
}

.option,
.hexPreview {
    width: 16px;
    height: 16px;
    background-color: #000000;

}

.color-picker form {
    display: flex;
    align-items: center;
    column-gap: 5px;
    width: 100%;
}

.element-image {
    width: fit-content;
}

.element-image img {
    width: 100%;
    height: 100%;
}

.link {
    display: inline;
    position: relative;
}

.link-popup {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 6px 10px;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid lightgray;
    width: fit-content;
    z-index: 1;
}

.table-input {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 3px;
}

.table-unit {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
}

select {
    height: 30px;
    border: none;
    width: 6.9rem;
}