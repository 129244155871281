.sideBarItem {
  border-radius: 5px;
  height: 30px;
  padding: 10px;
  margin-top: 10px;
  background: #ccc;
}

.item {
  border-radius: 5px;
	border: 2px solid transparent;
  cursor: grab;
}

.item:hover {
	background-color: var(--button-back-primary);
	border: 2px solid var(--border-color);
}

.item.small {
  height: 42px;
  display: flex;
  align-items: center;

  gap: 10px;
}

.item.small img {
  margin-left: 5px;
  max-width: 30px;
}

.item.large {
  margin-top: 10px;
  margin-left: 10px;
  display: inline-block;
  text-align: center;
  width: 75px;
  height: 75px;
  padding: 10px;
}

.item.large img {
  width: 50px;
  max-width: 50px;
}

.row1 {
  position: relative;
  margin: 20px 50px 20px 30px;
  padding: 20px;
  border-radius: 10px;
  display: block;
}
.row2{
  position: relative;
  margin: 20px 50px 20px 30px;
  padding: 2%;
  display: block;
}
.row3 {
  position: relative;
  margin: 0px;
  border-radius: 10px;
  display: block;
}
.row4{
  position: relative;
  display: block;
}
.row5 {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: block;
}
.row6{
  position: relative;
  padding: 2%;
  display: block;
}

.editor table td {
  border: solid 1px black;
}



.dropZone {
  flex: 0 0 auto;
  transition: 200ms all;

  height: 0;
  border-radius: 15px;
  margin: 0 0;

}

.dropZone:nth-of-type(2n) {
  display: none;
}

.dropZone.moving,
.dropZone.active {
  height: 20px;
  border-radius: 15px;
}

.dropZone.horizontalDrag {
  width: 20px;
  margin: 0 10px;
  height: auto;
}

.row3 .dropZone.horizontalDrag:not(.moving), .row4 .dropZone.horizontalDrag:not(.moving) {
  width: 0;
  margin: 0;
}

.row3 .component, .row4 .component {
  padding: 0 !important; 
}

.dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
}

.dropZone.moving {
  background: var(--select-hover);
  transition: 200ms all;
}

.dropZone.moving:hover,
.dropZone.active {
  background: var(--blue-primary) !important;
  transition: 200ms all;
}

.column {
  flex: 1 1 100%;
}

.columns {
  display: flex;
}

.columnContainer {
  display: flex;
}

.base {
  cursor: move;
}


.editor-wrapper {
  cursor: text;
}

.toolbar {
  position: absolute !important;
  top: -35px;
  display: flex;
  align-items: center;
  z-index: 2;
  overflow: visible !important;
  padding: 3px 10px;
  animation: slide-in-up .1s ease;
}

.toolbar-group {
  display: flex;
}

.color-picker .popup {
  left: -160px;
}

.popup-wrapper {
  display: inline;
  position: relative;
}

.popup {
  position: absolute;
  left: 0;
  padding: 10px;
  height: fit-content;
  z-index: 1;
}


@keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}